import { inject } from '@angular/core';
import { CanActivateFn, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ListingService } from '../../api/services';
import { AuthService } from '../services';

export const canEditOrIsActiveGuard: CanActivateFn = (route, state):
  Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree => {
  const listingService = inject(ListingService);
  const authService = inject(AuthService);
  if(authService.isAuthenticated()){
    return listingService.canEditOrIsActive({ id: route.paramMap.get('id')! });
  }
  return listingService.listingIsActive({ id: route.paramMap.get('id')! });
};
