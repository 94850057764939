import { inject } from '@angular/core';
import { CanActivateFn, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth-service.service';

export const salesGuard: CanActivateFn = (route, state):
    Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree => {
    const authService = inject(AuthService);
    return authService.isUserSales() || authService.isUserAdmin();
};

