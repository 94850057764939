import { inject } from '@angular/core';
import { CanActivateFn, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ListingService } from '../../api/services';


export const canEditGuard: CanActivateFn = (route, state):
    Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree => {
    const listingService = inject(ListingService);
    return listingService.canEdit({ id: route.paramMap.get('id')! });
};
