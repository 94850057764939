import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { AuthService } from '../services/auth-service.service';
import { inject } from '@angular/core';
import { Observable, tap } from 'rxjs';

export const authGuard: CanActivateFn = (route, state):
  Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree => {
  const router = inject(Router);
  return inject(AuthService).isAuthenticated().pipe(tap(res => {
    if (!res) {
      router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    }
  }));
};

